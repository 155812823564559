/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

/* -------------------------------------------------------------------------- */
/*                             Internal Dependency                            */
/* -------------------------------------------------------------------------- */
import { logout } from "../redux/actions/AuthActions";
import { setupInterceptors } from "../utils/api";
import { createModal } from "../utils/modals";
import AccessDeniedModal from "./AccessDeniedModal";

const InterceptorSetup = () => {
  const dispatch = useDispatch();

  const openAccessDeniedModal = () => {
    const modal = createModal({
      body: <AccessDeniedModal />,
      canClose: false,
      options: {
        title: "Access Denied",
        ok: "Go to Dashboard",
        hideCancel: true,
        dismissAsCancel: false,
      },
      proceed: () => window.location.replace("/dashboard"),
    });

    modal.open();
  };

  const handleLogOut = () => {
    dispatch(logout());
  };

  useEffect(() => {
    setupInterceptors({ handleLogOut, openAccessDeniedModal });
  }, []);

  return null;
};

export default InterceptorSetup;
