/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useMemo, useState } from "react";

import { debounce } from "lodash";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import styled from "styled-components";

import Button from "../../../../components/Button";
import Icon from "../../../../components/Icon";
import IconButton from "../../../../components/IconButton";
import InputGroup from "../../../../components/InputGroup";
/* ------------------------- Component dependencies ------------------------- */
import SectionNav from "../../../../components/SectionNav";
import SearchIcon from "../../../../components/svg/SearchIcon";
import { RESET_SKILLS_ERRORS } from "../../../../configs/constants/ActionTypes";
import {
  createSkill,
  listSkills,
  listSkillSuggestions,
  suggestSkill,
} from "../../../../redux/actions/SkillAction";
import { isClient, isDev } from "../../../../utils/auth";
import { createModal, openAlertModal } from "../../../../utils/modals";
import AddSkillForm from "./AddSkillForm";
import styles from "./style.scss";

const options = [
  { value: "", label: "Show: all" },
  { value: "language", label: " Show: Programming Languages" },
  { value: "framework", label: "Show: Frameworks" },
  { value: "platform", label: "Show: Platforms" },
  { value: "library", label: "Show: Libraries " },
  { value: "storage", label: "Show: Storage Engines" },
  { value: "other", label: "Show: Miscellaneous " },
];

// istanbul ignore next
const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: "99999",
    width: "250px",
    borderRadius: "4px",
    border: "1px solid #E3E9F2",
    background: "#FFF",
    color: "#151A30",
    boxShadow: "0px 14px 14px 0px #000",
  }),
  control: (base) => ({
    ...base,
    height: "100%",
    width: "180px",
    border: "1px solid 	#cccccc",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid 	#cccccc",
    },
  }),
  indicatorSeparator: (base) => ({ ...base, display: "none" }),
  option: (base, { isFocused }) => ({
    ...base,
    background: "#fff",
    color: "#151A30",
    fontWeight: "700",
    fontSize: "14px ",
    "&:hover": {
      backgroundColor: isFocused ? "#fff" : "#fff",
    },
  }),
  input: (base) => ({
    ...base,
    textAlign: "right",
  }),
};

const SkillsContainer = ({ children, skill_type, setSkillType, fetchSkills }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const sendQuery = (query) => fetchSkills(1, query);
  const delayedQuery = useMemo(() => debounce((q) => sendQuery(q), 500), []);
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname.split("/")[3];
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    delayedQuery(event.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
    sendQuery("");
  };

  const handleSkillSuggestions = (name, type, modal) => {
    modal.setIsLoading(true);
    const feedbackCb = {
      successCb: () => {
        modal.setIsLoading(false);
        modal.close();
        openAlertModal("Skill suggested successfully");
        dispatch(listSkillSuggestions());
      },
      failureCb: () => {
        modal.setIsLoading(false);
      },
    };
    dispatch(suggestSkill({ name, type }, feedbackCb));
  };

  const handleCreateStandardSkill = ({ name, type, assessmentUrl, modal }) => {
    modal.setIsLoading(true);
    const feedbackCb = {
      successCb: () => {
        modal.setIsLoading(false);
        modal.close();
        openAlertModal("Skill added successfully");
        dispatch(listSkills());
      },
      failureCb: () => {
        modal.setIsLoading(false);
      },
    };
    dispatch(createSkill({ name, type, assessment_url: assessmentUrl }, feedbackCb));
  };

  const handleAddSkill = () => {
    const modal = createModal({
      body: <AddSkillForm id="add-skill-form" />,
      options: {
        title: "Add new skill",
        canClose: true,
        className: "modal-payments modal-skills",
        ok: "Add Skill",
        size: "md",
        form: {
          type: "submit",
          form: "add-skill-form",
        },
      },
      beforeClose: () => {
        dispatch({ type: RESET_SKILLS_ERRORS });
      },
      proceed: ({ name, type, assessmentUrl }) => {
        dispatch({ type: RESET_SKILLS_ERRORS });
        if (pathname === "library") {
          handleCreateStandardSkill({ name, type, assessmentUrl, modal });
        }
        if (pathname === "suggestions") {
          handleSkillSuggestions(name, type, modal);
        }
      },
    });
    modal.open();
  };

  if (isDev() || isClient()) {
    return <Redirect to="/settings/profile" />;
  }
  return (
    <>
      <SectionNav
        style={{
          marginBottom: "0px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        links={[
          {
            route: `settings/skills/library`,
            name: "SKILLS LIBRARY",
          },
          {
            route: `settings/skills/suggestions`,
            name: "SUGGESTED SKILLS",
          },
        ]}
        rightActions={
          <div className={styles}>
            <div className="d-flex">
              <StyledSearchInput
                data-testid="input-search"
                name="search1"
                value={searchTerm}
                autoComplete="off"
                onChange={handleChange}
                className="input-search me-3"
                placeholder="Search...."
                prepend={<SearchIcon />}
                isAppendText={false}
                append={
                  <IconButton
                    data-testid="close-icon"
                    className="search-close-btn bsd-search-ic"
                    name="x-circle"
                  />
                }
                appendFunc={clearSearch}
              />

              <Select
                id="skill-filter"
                data-testid="skill-filter"
                aria-labelledby="skill-filter-select"
                styles={customStyles}
                options={options}
                value={skill_type}
                onChange={setSkillType}
                isSearchable={false}
              />

              <StyledButton
                id="newSkill"
                variant="primary"
                data-testid="newSkill"
                onClick={() => handleAddSkill()}
                style={{ marginLeft: "15px", fontSize: "16px" }}
              >
                <Icon name="round-add" />
                &nbsp;&nbsp;&nbsp;Skill
              </StyledButton>
            </div>
          </div>
        }
      />
      {children}
    </>
  );
};

const StyledSearchInput = styled(InputGroup)`
  box-shadow: none;
  border: 1px solid rgb(237, 241, 247);
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 15px;
  background: #fff;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 5px;
  padding-left: 4px;

  .input-group-prepend .input-group-text {
    background-color: rgb(255, 255, 255);
    color: rgb(62, 72, 87);
    padding: 0 5px;
    font-size: 16px;
    height: initial;
    border: none;
  }

  > div {
    margin: 0;

    input {
      width: 145px;
      padding: 0 5px;
      border: none;
      &:focus {
        border: none !important;
      }
    }
  }

  .search-close-btn {
    display: flex;
    align-items: center;
    padding: 0;
    color: rgb(143, 155, 179);
    font-size: 16px;
  }
`;

const StyledButton = styled(Button)`
  box-shadow: none;
`;

SkillsContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  skill_type: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  setSkillType: PropTypes.func,
  fetchSkills: PropTypes.func,
};

export default SkillsContainer;
