/* eslint-disable default-param-last */
import { combineReducers } from "redux";

import * as actionTypes from "../../configs/constants/ActionTypes";

const skills = (state = {}, action) => {
  const selection_key = action?.data?.selection || "default";
  const new_state = {};

  switch (action.type) {
    case actionTypes.GET_SKILLS_SUCCESS:
      new_state[selection_key] = action.data.items;
      return { ...state, ...new_state };

    case actionTypes.INVALIDATE_SKILLS:
      new_state[selection_key] = [];
      return { ...state, ...new_state };

    default:
      return state;
  }
};

function isFetching(state = {}, action) {
  const selection_key = action?.data?.selection || "default";
  const new_state = {};

  switch (action.type) {
    case actionTypes.GET_SKILLS_START:
      new_state[selection_key] = true;
      return { ...state, ...new_state };

    case actionTypes.GET_SKILLS_SUCCESS:
    case actionTypes.GET_SKILLS_FAILED:
      new_state[selection_key] = false;
      return { ...state, ...new_state };

    case actionTypes.LIST_SKILLS_LIBRARY_START:
    case actionTypes.LIST_SUGGESTED_SKILLS_START:
      return { loading: true };

    case actionTypes.LIST_SKILLS_LIBRARY_SUCCESS:
    case actionTypes.LIST_SUGGESTED_SKILLS_SUCCESS:
    case actionTypes.LIST_SKILLS_LIBRARY_FAILED:
    case actionTypes.LIST_SUGGESTED_SKILLS_FAILED:
      return { loading: false };

    case actionTypes.LIST_ACTIVITIES_START:
      return { assessments: true };

    case actionTypes.LIST_ASSESSMENTS_SUCCESS:
    case actionTypes.LIST_ASSESSMENTS_FAILED:
      return { assessments: false };
    default:
      return state;
  }
}

const skillLibrary = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.LIST_SKILLS_LIBRARY_START:
      return {
        ...state,
      };
    case actionTypes.LIST_SKILLS_LIBRARY_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

const assessments = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.LIST_ASSESSMENTS_START:
      return {
        ...state,
      };
    case actionTypes.LIST_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

const skillSuggestions = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.LIST_SUGGESTED_SKILLS_START:
      return {
        ...state,
      };
    case actionTypes.LIST_SUGGESTED_SKILLS_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

function errors(state = {}, action) {
  switch (action.type) {
    case actionTypes.CREATE_SKILL_FAILED:
      return { create_skill_error: action.error };
    case actionTypes.SUGGEST_SKILL_FAILED:
      return {
        skill_suggestion_error: action.error.replaceAll(
          "Suggested skill with this name already exists.",
          "",
        ),
      };
    case actionTypes.ADD_TO_SKILL_LIBRARY_FAILED:
      return { approve_skill_error: action.error };
    case actionTypes.REJECT_SKILL_FAILED:
    case actionTypes.EDIT_SKILL_FAILED:
    case actionTypes.EDIT_SUGGESTED_SKILL_FAILED:
      return { edit_skill_error: action.error };
    case actionTypes.RESET_SKILLS_ERRORS:
      return {};
    default:
      return state;
  }
}

const Skill = combineReducers({
  skills,
  skillLibrary,
  skillSuggestions,
  assessments,
  isFetching,
  errors,
});

export default Skill;
