import React, { useState } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Error from "../../../../components/Error";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import FieldError from "../../../../components/FieldError";
import { validateUrl } from "../../../../utils/helpers";

const options = [
  { value: "language", name: " Programming Languages" },
  { value: "framework", name: "Frameworks" },
  { value: "platform", name: "Platforms" },
  { value: "library", name: "Libraries " },
  { value: "storage", name: "Storage Engines" },
  { value: "other", name: " Miscellaneous " },
];

const AddSkillForm = (props) => {
  const { id, proceed } = props;
  const [name, setSkillName] = useState("");
  const [assessmentUrl, setAssessmentUrl] = useState("");
  const [type, setSkillType] = useState(options[0].value);
  const [isUrlValid, setIsUrlValid] = useState(true);

  const { errors } = useSelector((state) => state.Skill);
  const errorMessage = errors?.create_skill_error || errors?.skill_suggestion_error;

  const handleChange = (e) => {
    setSkillName(e.target.value);
  };

  const handleTypeChange = (e) => {
    setSkillType(e.target.value);
  };

  const handleUrlValidation = (e) => {
    const valid = validateUrl(e.target.value);

    setIsUrlValid(valid);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (!isUrlValid) return;
    proceed({ name, type, assessmentUrl });
  };

  return (
    <form id={id} onSubmit={onSubmit} data-testid="add-skill-form">
      {errorMessage && <Error message={errorMessage} />}
      <label
        className="control-label mb-2"
        htmlFor="skill_name"
        style={{ fontSize: "14px", width: "100%" }}
      >
        Skill name
      </label>

      <Input
        className="mb-1"
        id="skill_name"
        value={name}
        type="text"
        dataTestId="skill_name"
        placeholder="Enter skill name"
        onChange={handleChange}
        required
      />

      <label
        className="control-label mb-2 mt-3"
        htmlFor="skill-type"
        style={{ fontSize: "14px", width: "100%" }}
      >
        Associated skills type
      </label>
      <Select
        id="skill-type"
        name="skill-type"
        onChange={handleTypeChange}
        aria-label="skill-type-input"
        options={options}
        required
      >
        <option value="">Select skill type</option>
      </Select>

      <label
        className="control-label mb-2 mt-3"
        htmlFor="assessment_url"
        style={{ fontSize: "14px", width: "100%" }}
      >
        Coderbyte Assessment link
      </label>

      <Input
        id="assessment_url"
        value={assessmentUrl}
        type="text"
        dataTestId="assessment_url"
        placeholder="Enter link to the assessment"
        onChange={(e) => setAssessmentUrl(e.target.value)}
        onKeyUp={handleUrlValidation}
      />
      {!isUrlValid && <FieldError message="Please enter a valid URL" />}
    </form>
  );
};

AddSkillForm.propTypes = {
  id: PropTypes.string.isRequired,
  acct_type: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  proceed: PropTypes.func,
};

export default AddSkillForm;
