import axios from "axios";

import * as actionTypes from "../../configs/constants/ActionTypes";
import { failed, formatErrorResponse, start, success } from "../../utils/actions";
import { ENDPOINT_SKILL_SUGGESTIONS, ENDPOINT_SKILLS, getCancelToken } from "../../utils/api";

const getSkillsStart = (filter, selection, prev_selection) => {
  return {
    type: actionTypes.GET_SKILLS_START,
    data: {
      filter,
      selection,
      prev_selection,
    },
  };
};

export function getSkills(filter, selection, prev_selection) {
  return (dispatch) => {
    dispatch(getSkillsStart(filter, selection, prev_selection));
    axios
      .get(ENDPOINT_SKILLS, { params: filter })
      .then(({ data }) => {
        dispatch(
          success(actionTypes.GET_SKILLS_SUCCESS, {
            items: data.results,
            previous: data.previous,
            next: data.next,
            count: data.count,
            selection,
            prev_selection,
          }),
        );
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_SKILLS_FAILED,
          data: {
            error: formatErrorResponse(error),
            selection,
            prev_selection,
          },
        });
      });
  };
}

export const listSkills = (filter) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LIST_SKILLS_LIBRARY_START,
    });

    axios
      .get(ENDPOINT_SKILLS, {
        params: filter,
        cancelToken: getCancelToken("listSkills"),
      })
      .then((response) => {
        dispatch(success(actionTypes.LIST_SKILLS_LIBRARY_SUCCESS, { ...response.data }));
      })
      .catch((error) => {
        if (error.message === "aborted") return;

        dispatch(
          failed(
            actionTypes.LIST_SKILLS_LIBRARY_FAILED,
            error.response
              ? error.response.data
              : {
                  message: "Could not fetch results",
                },
          ),
        );
      });
  };
};

export const listSkillSuggestions = (filter) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LIST_SUGGESTED_SKILLS_START,
    });

    axios
      .get(ENDPOINT_SKILL_SUGGESTIONS, {
        params: filter,
        cancelToken: getCancelToken("listSkillSuggestions"),
      })
      .then((response) => {
        dispatch(success(actionTypes.LIST_SUGGESTED_SKILLS_SUCCESS, { ...response.data }));
      })
      .catch((error) => {
        if (error.message === "aborted") return;

        dispatch(
          failed(
            actionTypes.LIST_SKILLS_LIBRARY_FAILED,
            error.response
              ? error.response.data
              : {
                  message: "Could not fetch results",
                },
          ),
        );
      });
  };
};

export const createSkill = (data, { successCb, failureCb }) => {
  return (dispatch) => {
    dispatch(start(actionTypes.CREATE_SKILL_START));
    axios
      .post(ENDPOINT_SKILLS, data)
      .then((response) => {
        if (successCb) successCb();
        dispatch(success(actionTypes.CREATE_SKILL_SUCCESS, response.data));
        dispatch(listSkills());
      })
      .catch((error) => {
        const errorResponse = formatErrorResponse(error);
        if (failureCb) failureCb(errorResponse);
        dispatch(failed(actionTypes.CREATE_SKILL_FAILED, errorResponse));
      });
  };
};

export const suggestSkill = (payload, { successCb, failureCb }, isBulk = false) => {
  return (dispatch) => {
    dispatch(start(actionTypes.SUGGEST_SKILL_START));
    axios
      .post(`${ENDPOINT_SKILL_SUGGESTIONS}${isBulk ? "bulk/" : ""}`, payload)
      .then((response) => {
        if (successCb) successCb();
        dispatch(success(actionTypes.SUGGEST_SKILL_SUCCESS, response.data));
        dispatch(listSkillSuggestions());
      })
      .catch((error) => {
        const errorResponse = formatErrorResponse(error);
        if (failureCb) failureCb(errorResponse);
        dispatch(failed(actionTypes.SUGGEST_SKILL_FAILED, errorResponse));
      });
  };
};

export const addToSKillLibrary = (id, status, { successCb, failureCb }) => {
  return (dispatch) => {
    dispatch(start(actionTypes.ADD_TO_SKILL_LIBRARY_START));
    axios
      .patch(`${ENDPOINT_SKILL_SUGGESTIONS}${id}/`, status)
      .then((response) => {
        if (successCb) successCb();
        dispatch(success(actionTypes.ADD_TO_SKILL_LIBRARY_SUCCESS, response.data));
        dispatch(listSkillSuggestions());
      })
      .catch((error) => {
        const errorResponse = formatErrorResponse(error);
        if (failureCb) failureCb(errorResponse);
        dispatch(failed(actionTypes.ADD_TO_SKILL_LIBRARY_FAILED, errorResponse));
      });
  };
};

export const bulkAddToSkillLibrary = (data, { successCb, failureCb }) => {
  return (dispatch) => {
    dispatch(start(actionTypes.ADD_TO_SKILL_LIBRARY_START));
    axios
      .post(`${ENDPOINT_SKILL_SUGGESTIONS}bulk-action/`, data)
      .then((response) => {
        if (successCb) successCb();
        dispatch(success(actionTypes.ADD_TO_SKILL_LIBRARY_SUCCESS, response.data));
        dispatch(listSkillSuggestions());
      })
      .catch((error) => {
        const errorResponse = formatErrorResponse(error);
        if (failureCb) failureCb(errorResponse);
        dispatch(failed(actionTypes.ADD_TO_SKILL_LIBRARY_FAILED, errorResponse));
      });
  };
};

export const rejectSkill = (id, data, { successCb, failureCb }) => {
  return (dispatch) => {
    dispatch(start(actionTypes.REJECT_SKILL_START));
    axios
      .patch(`${ENDPOINT_SKILL_SUGGESTIONS}${id}/`, data)
      .then((response) => {
        if (successCb) successCb();
        dispatch(success(actionTypes.REJECT_SKILL_SUCCESS, response.data));
        dispatch(listSkillSuggestions());
      })
      .catch((error) => {
        const errorResponse = formatErrorResponse(error);
        if (failureCb) failureCb(errorResponse);
        dispatch(failed(actionTypes.REJECT_SKILL_FAILED, errorResponse));
      });
  };
};

export const updateSkill = (id, data, { successCb, failureCb }) => {
  return (dispatch) => {
    dispatch(start(actionTypes.EDIT_SKILL_START));
    axios
      .patch(`${ENDPOINT_SKILLS}${id}/`, data)
      .then((response) => {
        if (successCb) successCb();
        dispatch(success(actionTypes.EDIT_SKILL_SUCCESS, response.data));
      })
      .catch((error) => {
        const errorResponse = formatErrorResponse(error);
        if (failureCb) failureCb(errorResponse);
        dispatch(failed(actionTypes.EDIT_SKILL_FAILED, errorResponse));
      });
  };
};

export const updateSuggestedSkill = (id, data, { successCb, failureCb }) => {
  return (dispatch) => {
    dispatch(start(actionTypes.EDIT_SUGGESTED_SKILL_START));
    axios
      .patch(`${ENDPOINT_SKILL_SUGGESTIONS}${id}/`, data)
      .then((response) => {
        if (successCb) successCb();
        dispatch(success(actionTypes.EDIT_SUGGESTED_SKILL_SUCCESS, response.data));
      })
      .catch((error) => {
        const errorResponse = formatErrorResponse(error);
        if (failureCb) failureCb(errorResponse);
        dispatch(failed(actionTypes.EDIT_SUGGESTED_SKILL_FAILED, errorResponse));
      });
  };
};

export const listAssessment = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LIST_ASSESSMENTS_START,
    });

    axios
      .get(`${ENDPOINT_SKILLS}?has_assessment_url=true`)
      .then((response) => {
        dispatch(success(actionTypes.LIST_ASSESSMENTS_SUCCESS, { ...response.data }));
      })
      .catch((error) => {
        dispatch(failed(actionTypes.LIST_ASSESSMENTS_FAILED, formatErrorResponse(error)));
      });
  };
};
