/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

/* -------------------- Internel Dependencies (Utilites) -------------------- */

/* ------------------------- Component dependencies ------------------------- */
import TextArea from "../../../components/TextArea";
import Input from "../../../components/Input";
import { cleanSkills } from "../../../utils/api";
import Success from "../../../components/Success";
import { updateCompany } from "../../../redux/actions/ProfileActions";
import FieldError from "../../../components/FieldError";
import SkillSelector from "../../../components/SkillSelector";
import { openAlertModal } from "../../../utils/modals";

const CompanyProfile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ Auth }) => Auth);
  const { isSaved, errors } = useSelector(({ Profile: UserProfile }) => UserProfile);

  const [company, setCompany] = useState({
    name: user.company?.name || "",
    website: user.company?.website || "",
    bio: user.company?.bio || "",
    skills: user.company?.skills || "",
  });

  const onChangeValue = (key, value) => {
    const newState = {};
    newState[key] = value;
    setCompany({ ...company, ...newState });
  };

  const onChangeField = (key, e) => {
    onChangeValue(key, e.target.value);
  };

  /* istanbul ignore next */
  const onSkillChange = (newSkillsValue) => {
    const newSkills = cleanSkills(newSkillsValue).map((skill) => {
      return { ...skill, type: skill?.type };
    });

    onChangeValue("skills", [...newSkills]);
  };

  const onSave = (e) => {
    e.preventDefault();
    const feedbackCb = {
      successCb: null,
      failureCb: (err) => {
        openAlertModal(
          err.message?.[0] || err.message || "Sorry, an error occurred, please try again.",
          true,
        );
      },
    };
    dispatch(updateCompany({ id: user.company?.id, company, feedbackCb }));
  };

  return (
    <ContentSection className="company-settings">
      {isSaved.company && (
        <Success data-testid="successMsg" message="Company profile saved successfully" />
      )}
      <form onSubmit={onSave}>
        <div className="row">
          <div className="col-sm-12">
            <FormGroup>
              <label className="control-label mb-2" htmlFor="company">
                Company Name
                <span className="label-style">*</span>
              </label>
              <Input
                placeholder="Company name"
                onChange={(e) => onChangeField("name", e)}
                value={company?.name}
                id="company"
              />
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            {errors.company && errors.company.website && (
              <FieldError data-testid="websiteError" message={errors.company.website} />
            )}
            <FormGroup>
              <label className="control-label mb-2" htmlFor="website">
                Website
              </label>
              <Input
                placeholder="https://example.com"
                onChange={(e) => onChangeField("website", e)}
                value={company?.website}
                id="website"
              />
            </FormGroup>
          </div>
        </div>
        <div className="row" style={{ marginBottom: 10 }}>
          <div className="col-sm-12">
            <FormGroup>
              <label className="control-label mb-2" htmlFor="bio">
                Company Bio
              </label>
              <TextArea
                placeholder="Enter bio here"
                onChange={(e) => onChangeField("bio", e)}
                value={company?.bio}
                id="bio"
              />
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <FormGroup>
              <label className="control-label mb-2" htmlFor="tech">
                Technologies used by my company
              </label>
              <SkillSelector
                placeholder="Type to search and select..."
                onChange={(sk) => onSkillChange(sk)}
                selected={company.skills || []}
                id="tech"
              />
            </FormGroup>
          </div>
        </div>

        <div className="col-12 save-container">
          <button
            type="submit"
            className="btn btn-primary float-right save"
            disabled={!company?.name}
          >
            Save
          </button>
        </div>
      </form>
    </ContentSection>
  );
};

const ContentSection = styled.div`
  padding-bottom: 100px !important;

  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
  .save-container {
    background: rgba(237, 241, 247, 0.25);
    height: fit-content;
    display: flex;
    padding: 20px 40px;
    position: absolute;
    bottom: 0;
    left: 0;

    button {
      margin: 0 0 0 auto;
    }
  }
  .file-drop {
    /* relatively position the container bc the contents are absolute */
    position: relative;
    height: 100px;
    width: 100%;
    background: #ffffff;
    border: 1px dashed #c2ccd9;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    text-align: center;

    color: #8f9bb3;
    span {
      position: relative;
      color: #062e64;
      font-weight: 500;
      margin: 0 2px;
      input {
        position: absolute;
        width: 100%;
        opacity: 0;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }

  .file-drop > .file-drop-target {
    /* basic styles */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 2px;

    /* horizontally and vertically center all content */
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

    flex-direction: row;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;

    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;

    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;

    align-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;

    text-align: center;
  }

  .file-drop > .file-drop-target.file-drop-dragging-over-frame {
    /* overlay a black mask when dragging over the frame */
    border: none;
    background-color: rgba(0, 0, 0, 0.65);
    box-shadow: none;
    z-index: 50;
    opacity: 1;

    /* typography */
    color: white;
  }

  .file-drop > .file-drop-target.file-drop-dragging-over-target {
    /* turn stuff orange when we are dragging over the target */
    color: #ff6e40;
    box-shadow: 0 0 13px 3px #ff6e40;
  }
  hr {
    border-top: 1px solid #edf1f7;
    margin: 2rem 0 !important;
    opacity: 1;
  }
  .header {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #151a30;
    margin-bottom: 24px;
  }

  form,
  .form__onboard {
    .control-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #151a30;
    }

    .profile-pic {
      position: relative;
      margin-right: 40px;
      height: fit-content;

      button {
        border: none;
        border-radius: 50%;
        background: #fff;
        min-width: 25px;
        height: 25px;
        position: absolute;
        bottom: 0;
        right: 10px;
        box-shadow: 0px 3px 8px rgba(143, 155, 179, 0.5);
        padding: 0;

        &.btn {
          font-size: initial;
          line-height: 0px;
        }

        .action-text {
          display: none;
        }

        i {
          color: #232735;
        }
      }
    }

    input,
    select {
      background: #ffffff;
      border: 1px solid rgba(194, 204, 217, 0.25);
      box-sizing: border-box;
      box-shadow: none;
      border-radius: 4px;
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 24px;
      color: #3e4857;
    }

    .avatar {
      width: 100px;
      height: 100px;

      &.avatar-icon i {
        font-size: 100px;
      }

      &.avatar-initials {
        font-size: 24px !important;
      }
    }

    .save {
      box-shadow: none;
      border: none;
      background: rgba(6, 46, 100, 0.05);
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #062e64;

      &:disabled {
        color: rgba(6, 46, 100, 0.25);
      }
    }
  }
`;

export default CompanyProfile;
