import React, { useState } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Error from "../../../../components/Error";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import FieldError from "../../../../components/FieldError";
import { validateUrl } from "../../../../utils/helpers";

const options = [
  { value: "language", name: " Programming Languages" },
  { value: "framework", name: "Frameworks" },
  { value: "platform", name: "Platforms" },
  { value: "library", name: "Libraries " },
  { value: "storage", name: "Storage Engines" },
  { value: "other", name: " Miscellaneous " },
];

const EditSkillForm = ({ id, skill_name, skill_type, assessment_url, proceed }) => {
  const [name, setSkillName] = useState(skill_name);
  const [type, setSkillType] = useState(skill_type);
  const [assessmentUrl, setAssessmentUrl] = useState(assessment_url);
  const [isUrlValid, setIsUrlValid] = useState(true);

  const { errors } = useSelector((state) => state.Skill);
  const errorMessage = errors?.edit_skill_error || errors?.edit_skill_error;
  const handleChange = (e) => {
    setSkillName(e.target.value);
  };
  const handleTypeChange = (e) => {
    setSkillType(e.target.value);
  };

  const handleUrlValidation = (e) => {
    const valid = validateUrl(e.target.value);

    setIsUrlValid(valid);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    proceed({ name, type, assessmentUrl });
  };

  return (
    <form id={id} onSubmit={onSubmit} data-testid="edit-skill-form">
      {errorMessage && <Error message={errorMessage} />}
      <label
        className="control-label mb-2"
        htmlFor="form-title"
        style={{ fontSize: "14px", width: "100%" }}
      >
        Skill name
      </label>

      <Input
        className="mb-1"
        id="skill_name"
        value={name}
        type="text"
        dataTestId="skill_name"
        placeholder="Enter skill name"
        onChange={handleChange}
        required
      />

      <label
        className="control-label mb-2 mt-3"
        htmlFor="form-title"
        style={{ fontSize: "14px", width: "100%" }}
      >
        Associated skills type
      </label>
      <Select
        value={type}
        id="skill_type"
        name="skill_type"
        data-testid="skill_type"
        onChange={handleTypeChange}
        aria-label="skill-type-input"
        options={options}
        required
      >
        <option value="">Select skill type</option>
      </Select>

      <label
        className="control-label mb-2 mt-3"
        htmlFor="assessment_url"
        style={{ fontSize: "14px", width: "100%" }}
      >
        Coderbyte Assessment link
      </label>

      <Input
        id="assessment_url"
        value={assessmentUrl}
        type="text"
        dataTestId="assessment_url"
        placeholder="Enter link to the assessment"
        onChange={(e) => setAssessmentUrl(e.target.value)}
        onKeyUp={handleUrlValidation}
      />
      {!isUrlValid && <FieldError message="Please enter a valid URL" />}
    </form>
  );
};

EditSkillForm.propTypes = {
  id: PropTypes.string.isRequired,
  skill_type: PropTypes.string,
  skill_name: PropTypes.string,
  assessment_url: PropTypes.string,
  proceed: PropTypes.func,
};

export default EditSkillForm;
