import React, { useState } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Error from "../../../../components/Error";
import Select from "../../../../components/Select";

const options = [
  { value: "Duplication", name: " Duplication" },
  { value: "Not a Technical Skill", name: "Not a Technical Skill" },
  { value: "Skill Not Aligned with Platform Focus", name: "Skill Not Aligned with Platform Focus" },
  { value: "Skill Highly Specialized or Niche", name: "Skill Highly Specialized or Niche" },
  { value: "Other", name: "Other" },
];

const RejectSkillForm = (props) => {
  const { id, proceed } = props;
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [err, setErr] = useState("");
  const { errors } = useSelector((state) => state.Skill);

  const errorMessage = errors?.reject_skill_error || errors?.reject_skill_error || err;

  const handleChange = (e) => {
    setReason(e.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setErr("");

    if (reason === "Other" && !otherReason) {
      return setErr("Please enter a reason");
    }

    const r = reason === "Other" ? otherReason : reason;
    return proceed({ reason: r });
  };

  return (
    <form id={id} onSubmit={onSubmit} data-testid="reject-skill-form">
      {errorMessage && <Error message={errorMessage} />}

      <label
        className="control-label mb-2 mt-3 reject-skill-label"
        htmlFor="skill-reject-reason"
        style={{ fontSize: "14px", width: "100%" }}
      >
        Reason for rejection
      </label>

      <Select
        id="skill-reject-reason"
        name="skill-reject-reason"
        onChange={handleChange}
        aria-label="skill-reject-input"
        options={options}
        required
      >
        <option value="">Select a reason</option>
      </Select>

      {reason === "Other" && (
        <div className="form-group mt-3">
          <label className="control-label mb-2 reject-skill-label" htmlFor="other_reason">
            Please specify your reason
          </label>

          <textarea
            id="other_reason"
            name="other_reason"
            className="form-control"
            style={{ resize: "none" }}
            placeholder="Type message..."
            onChange={(e) => setOtherReason(e.target.value)}
            onBlur={() => setErr("")}
            value={otherReason}
            data-testid="other_reason"
          />
        </div>
      )}
    </form>
  );
};

RejectSkillForm.propTypes = {
  id: PropTypes.string.isRequired,
  proceed: PropTypes.func,
};

export default RejectSkillForm;
