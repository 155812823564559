import React, { useEffect, useMemo, useState } from "react";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import ReactTable from "../../../../components/ReactTable";
import { RESET_SKILLS_ERRORS } from "../../../../configs/constants/ActionTypes";
import useDidUpdate from "../../../../hooks/useDidUpdate";
import { listSkills, updateSkill } from "../../../../redux/actions/SkillAction";
import { cancelLastRequest } from "../../../../utils/api";
import randomstring from "../../../../utils/generateRandomString";
import { createModal, openAlertModal } from "../../../../utils/modals";
import { ContentSection } from "../../../../utils/styles";
import EditSkillForm from "./EditSkillForm";
import SkillsContainer from "./SkillsContainer";
import OverlayTooltip from "../../../../components/OverlayTooltip";

const SkillsLibrary = () => {
  const [selection] = useState({ selectionKey: randomstring.generate() });
  const { isFetching, isSaved, skillLibrary } = useSelector(({ Skill }) => Skill);
  const skillsData = skillLibrary?.results || [];
  const count = skillLibrary?.count;
  const [page_size, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [skill_type, setSkillType] = useState({ value: "", label: "Show: all" });

  const dispatch = useDispatch();

  const onLoadMore = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(+pageSize);
  };

  const fetchSkills = (page, search = "") => {
    const updatedFilters = {
      page,
      search,
      page_size,
      type: skill_type.value,
    };
    cancelLastRequest("listSkills");
    listSkills({ ...(updatedFilters || {}) }, selection.selectionKey)(dispatch);
  };

  useEffect(() => {
    fetchSkills(1);
    setCurrentPage(0);
  }, [skill_type]);

  useDidUpdate(() => {
    fetchSkills(currentPage + 1);
  }, [isSaved, page_size, currentPage]);

  const handleEditSkill = ({ id, skills_name, skills_type, assessment_url }) => {
    const modal = createModal({
      body: (
        <EditSkillForm
          id="edit-skill-form"
          data-testid="edit-skill-form"
          skill_name={skills_name}
          skill_type={skills_type}
          assessment_url={assessment_url}
        />
      ),
      options: {
        title: "Update skill",
        canClose: true,
        ok: "Save Changes",
        form: {
          type: "submit",
          form: "edit-skill-form",
        },
      },
      beforeClose: () => {
        dispatch({ type: RESET_SKILLS_ERRORS });
      },
      proceed: ({ name, type, assessmentUrl }) => {
        dispatch({ type: RESET_SKILLS_ERRORS });
        modal.setIsLoading(true);
        const feedbackCb = {
          successCb: () => {
            modal.setIsLoading(false);
            modal.close();
            openAlertModal("Skill edited successfully");
            fetchSkills(currentPage + 1);
          },
          failureCb: () => {
            modal.setIsLoading(false);
          },
        };
        dispatch(updateSkill(id, { name, type, assessment_url: assessmentUrl }, feedbackCb));
      },
    });
    modal.open();
  };

  const data = useMemo(
    () => [
      ...skillsData.map((i) => {
        const row = {
          name: i.name,
          type: i.type,
          assessment: i?.test_assessment_url || "",
          popularity: i.popularity,
          developers: i.num_developers,
          date_created: i.created_at,
          actions: {
            id: i.id,
            skill: i.name,
            type: i.type,
            assessment_url: i?.test_assessment_url || "",
          },
        };
        return row;
      }),
    ],
    [skillsData],
  );

  const columns = useMemo(
    () => [
      {
        Header: "Skills",
        accessor: "name",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Assessment",
        accessor: "assessment",
      },
      {
        Header: "Popularity",
        accessor: "popularity",
      },
      {
        Header: "No. of Developers",
        accessor: "developers",
      },
      {
        Header: "Date Created",
        accessor: "date_created",
      },
      {
        Header: "",
        accessor: "actions",
      },
    ],
    [],
  );

  const getTableDisplayValue = (cell) => {
    const key = `${cell.column.id}-${cell.row.id}`;
    switch (cell.column.id) {
      case "name":
        return (
          <td key={`name${key}`} className="nowrap">
            {cell.value}
          </td>
        );
      case "type":
        return (
          <td key={`type${key}`} className="nowrap">
            {cell.value}
          </td>
        );
      case "assessment":
        return (
          <td key={`type${key}`} className="nowrap">
            {cell.value ? (
              <OverlayTooltip
                tooltipId={`assessment-${key}`}
                placement="bottom"
                overlay={cell.value}
                className="overlay"
                tooltipClassName="overlay-inner-star"
              >
                <a target="_blank" rel="noopener noreferrer" href={cell.value}>
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.975 14.51a1.05 1.05 0 0 0 0-1.485 2.95 2.95 0 0 1 0-4.172l3.536-3.535a2.95 2.95 0 1 1 4.172 4.172l-1.093 1.092a1.05 1.05 0 0 0 1.485 1.485l1.093-1.092a5.05 5.05 0 0 0-7.142-7.142L9.49 7.368a5.05 5.05 0 0 0 0 7.142c.41.41 1.075.41 1.485 0zm2.05-5.02a1.05 1.05 0 0 0 0 1.485 2.95 2.95 0 0 1 0 4.172l-3.5 3.5a2.95 2.95 0 1 1-4.171-4.172l1.025-1.025a1.05 1.05 0 0 0-1.485-1.485L3.87 12.99a5.05 5.05 0 0 0 7.142 7.142l3.5-3.5a5.05 5.05 0 0 0 0-7.142 1.05 1.05 0 0 0-1.485 0z"
                      fill="#000000"
                    />
                  </svg>
                </a>
              </OverlayTooltip>
            ) : (
              "N/A"
            )}
          </td>
        );
      case "popularity":
        return (
          <td key={`popularity${key}`} className="nowrap">
            {cell.value}%
          </td>
        );
      case "developers":
        return (
          <td key={`developers${key}`} className="nowrap">
            {cell.value}
          </td>
        );
      case "date_created":
        return (
          <td key={`date_created${key}`} className="nowrap">
            {moment.utc(cell.value).format("MMM D YYYY")}
          </td>
        );

      case "actions":
        return (
          <td key={`actions${key}`} className="d-table-cell">
            <div className="dropdown">
              <button
                style={{ boxShadow: "none" }}
                className="btn"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                type="button"
              >
                <span
                  style={{ fontSize: "1.5rem", color: "#8F9BB3" }}
                  data-testid={`dot-${cell.value.id}`}
                >
                  ...
                </span>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button
                  type="button"
                  data-testid={`edit-${cell.value.id}`}
                  className="dropdown-item"
                  style={{ background: "#fff", color: "#3e4857", cursor: "pointer" }}
                  onClick={() => {
                    handleEditSkill({
                      id: cell.value.id,
                      skills_name: cell.value.skill,
                      skills_type: cell.value.type,
                      assessment_url: cell.value.assessment_url,
                    });
                  }}
                >
                  Edit skill
                </button>
              </div>
            </div>
          </td>
        );
      default:
        return null;
    }
  };

  return (
    <SkillsContainer skill_type={skill_type} setSkillType={setSkillType} fetchSkills={fetchSkills}>
      <ContentSection style={{ paddingTop: "0" }}>
        <div className="section">
          <ReactTable
            scope="skill_library"
            tableData={data}
            tableColumns={columns}
            currentPage={currentPage}
            count={count}
            getTableDisplayValue={getTableDisplayValue}
            loadPage={onLoadMore}
            pageSize={page_size}
            isLoading={isFetching.loading}
            noDataMessage="No skills found"
          />
        </div>
      </ContentSection>
    </SkillsContainer>
  );
};

export default SkillsLibrary;
