import React from "react";

const AccessDeniedModal = () => {
  return (
    <>
      You don&apos;t have the necessary permissions to view this page. Please check your access
      rights or contact support for assistance.
    </>
  );
};

export default AccessDeniedModal;
